import React, { Fragment } from 'react';

const MadeBy = () => {
    return (
        <Fragment>
            <a className="made-by" href="https://twitter.com/igorpiatkov" target="_new" rel="noopener noreferrer">
                <div className="mention btn btn-sm">
                    <img src="https://igorpiatkov.com/igor-piatkov.jpg" alt="igor-piatkov" /><span>by Igor Piatkov</span>
                </div>
            </a>
        </Fragment>
    )
}

export default MadeBy;