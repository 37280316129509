import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo-fixed.svg"

const Footer = ({ siteTitle }) => (
<footer>
    <div className="nav nav-footer">
        <div className="container-created-by block">
            <ul className="inline-list">
                <li>created by <a href="https://twitter.com/igorpiatkov" target="_blank" className="created-by">igorpiatkov</a></li>
            </ul>
        </div>
        <div className="footer-logo block">
            <a href="/"><img className="logo" src={logo} alt="toggol logo" /></a>
        </div>
        <div className="block">
            <ul className="inline-list">
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                <li><a href="/terms-of-service">TOS</a></li>
            </ul>
        </div>
    </div>
</footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer