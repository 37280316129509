// Fixed menu on scroll
export default function fixedMenuOnScroll(defaultPadding, activatedPadding, activationPoint) {
    const navMain = document.querySelector('.nav-main');
    const navFixed = document.querySelector('.nav-fixed');

    window.addEventListener('scroll', () => {
        const hero = document.querySelector('.hero');
        if(window.pageYOffset > activationPoint) {
            navMain.classList.add('hidden');
            navFixed.classList.remove('hidden');

            // move hero from top to match default possition
            hero.style.paddingTop = "200px"
        } else {
            navMain.classList.remove('hidden');
            navFixed.classList.add('hidden');

            // move back to it's original position
            hero.style.paddingTop = defaultPadding
        }
    })
}