export default function mobileMenu() {
    // Mobile Menu
    const header = document.querySelector('.header');
    const mobileMenuContainer = document.querySelector('.mobile-menu-overlay');
    const body = document.body;

    header.addEventListener('click', (e) => {
        // Open Mobile Menu
        if(e.target.classList.contains('mobile-menu-icon')) {
            mobileMenuContainer.classList.toggle('hidden');
            body.style.overflow = "hidden";
        }

        // Close Mobile Menu (added listeners for 1. Close Icon, 2. Icon's Container, 3. Area Outside the menu (blured) and 4. Any Links )
        if(e.target.classList.contains('mobile-menu-close') || e.target.classList.contains('mobile-menu-overlay-close') || e.target.classList.contains('mobile-menu-overlay') || e.target.tagName === "A") {
            mobileMenuContainer.classList.add('hidden');
            body.style.overflow = "visible";
        }
    });

    // disable mobile menu if window's size is over 1023px
    window.addEventListener('resize', (e) => {
        if(e.currentTarget.innerWidth > 1023) {
            mobileMenuContainer.classList.add('hidden');
            body.style.overflow = "visible";
        }
    })
}